export function mergePermissions(userPermissions, groupPermissions) {
  const mergedPermissions = { ...groupPermissions };

  for (const key in userPermissions) {
    if (Array.isArray(userPermissions[key])) {
      // Merge arrays without duplicates
      mergedPermissions[key] = [
        ...new Set([
          ...(groupPermissions[key] || []),
          ...userPermissions[key],
        ]),
      ];
    } else if (typeof userPermissions[key] === 'boolean') {
      // Prioritize true values in boolean permissions
      mergedPermissions[key] = userPermissions[key] || groupPermissions[key];
    } else {
      // For other types, userPermissions overwrite groupPermissions if not null/undefined
      mergedPermissions[key] = userPermissions[key] !== null && userPermissions[key] !== undefined
        ? userPermissions[key]
        : groupPermissions[key];
    }
  }

  return mergedPermissions;
}



export function processRawQuestionsAndAnswersData(data) {
  const result = {
    steps: {}
  };

  data.results.forEach(item => {
    const step = item.data.Step;

    // Initialize step if it doesn't exist
    if (!result.steps[step]) {
      result.steps[step] = {
        questions: {},
        stepsMetaData: []
      };
    }

    if (item.data.QuestionType === 'step_meta_data') {
      result.steps[step].stepsMetaData.push({
        StepTitle: item.data.StepTitle,
        StepDescription: item.data.StepDescription,
        StepOrder: item.data.StepOrder
      });
    } else {
      const questionId = item.data.QuestionId;
      const answerId = item.data.AnswerId;

      // Initialize question if it doesn't exist
      if (!result.steps[step].questions[questionId]) {
        result.steps[step].questions[questionId] = {
          QuestionId: item.data.QuestionId,
          QuestionText: item.data.QuestionText,
          FieldName: item.data.FieldName,
          DefaultValue: item.data.DefaultValue,
          QuestionSubText: item.data.QuestionSubText,
          QuestionType: item.data.QuestionType,
          FlexBasis: item.data.FlexBasis,
          HideOnTrue: item.data.HideOnTrue,
          Tooltip: item.data.Tooltip,
          Popup: item.data.Popup,
          GoogleMapsType: item.data.GoogleMapsType,
          QuestionIcon: item.data.QuestionIcon,
          QuestionTypeId: item.data.QuestionTypeId,
          LanguageId: item.data.LanguageId,
          IsHidden: item.data.IsHidden,
          IsNonQuestion: item.data.IsNonQuestion,
          IsDontSendToClient: item.data.IsDontSendToClient,
          VuexAction: item.data.VuexAction,
          CssClass: item.data.CssClass,
          Obsolete_CssClass: item.data.Obsolete_CssClass,
          Obsolete_InLine: item.data.Obsolete_InLine,
          Obsolete_QuestionType: item.data.Obsolete_QuestionType,
          Obsolete_CrmType: item.data.Obsolete_CrmType,
          Obsolete_Action: item.data.Obsolete_Action,
          QuestionMarkPopup: item.data.QuestionMarkPopup,
          Obsolete_IsRelevant: item.data.Obsolete_IsRelevant,
          Obsolete_IsHalfLine: item.data.Obsolete_IsHalfLine,
          Obsolete_IsFieldDisabled: item.data.Obsolete_IsFieldDisabled,
          Obsolete_CategoryId: item.data.Obsolete_CategoryId,
          Obsolete_GroupId: item.data.Obsolete_GroupId,
          is_pencil: item.data.is_pencil,
          options_direction: item.data.options_direction,
          answers: []
        };
      }

      if (answerId !== "NULL" && answerId !== null) {
        result.steps[step].questions[questionId].answers.push({
          AnswerId: item.data.AnswerId,
          AnswerValue: item.data.AnswerValue,
          AnswerText: item.data.AnswerText,
          AnswerType: item.data.AnswerType,
          IsCorrect: item.data.IsCorrect,
          Explanation: item.data.Explanation,
          Hint: item.data.Hint,
          Order: item.data.Order,
          IsHidden: item.data.IsHidden,
          IsDisabled: item.data.IsDisabled,
          CssClass: item.data.CssClass,
          AnswerIcon: item.data.AnswerIcon,
          AnswerIconColor: item.data.AnswerIconColor,
          AnswerDescription: item.data.AnswerDescription,
          QuestionId: item.data.QuestionId
        });
      }
    }
  });

  console.log(result);
  return result;
}

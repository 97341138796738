import axios from 'axios';
import Vue from 'vue';
import axiosRetry from 'axios-retry';
import { getToken } from './util';
import has from 'lodash/has';
import router from '@/router';
import socket from '@/services/socket';
import { removeStorage, logout } from '@/services/auth';

const axiosParams = {
  baseURL: process.env.VUE_APP_BASE_API,
  responseType: 'json',
};

const errorInterceptor = (error) => {
  if (!error.response) {
    return Promise.reject(error);
  }

  switch (error.response.status) {
    case 401:
      Vue.prototype.$notify({
        message: error.response.data || 'Unauthorized access. Please log in.',
        icon: "tim-icons icon-bell-55",
        horizontalAlign: 'right',
        verticalAlign: 'top',
        type: 'danger',
        timeout: 3000,
      });
      logout(router);
      break;
    case 404:
      Vue.prototype.$notify({
        message: 'Something Went Wrong',
        icon: "tim-icons icon-bell-55",
        horizontalAlign: 'right',
        verticalAlign: 'top',
        type: 'danger',
        timeout: 3000,
      });
      break;
    case 403:
      Vue.prototype.$notify({
        message: 'You do not have permission to perform this action.',
        icon: "tim-icons icon-bell-55",
        horizontalAlign: 'right',
        verticalAlign: 'top',
        type: 'danger',
        timeout: 3000,
      });
      break;
    case 400:
      logout(router);
      Vue.prototype.$notify({
        message: error.response.data || 'An error occurred',
        icon: "tim-icons icon-bell-55",
        horizontalAlign: 'right',
        verticalAlign: 'top',
        type: 'danger',
        timeout: 3000,
      });
      break;
    default:
  }
  return Promise.reject(error.response.data);
};

const transformResponse = (res) => {
  const { data } = res || {};
  const { Token } = data || {};

  const transformDataObject = (data) => {
    if (!data) {
      return data;
    }
    return has(data, 'Data') ? data.Data : data;
  };

  const payload = {
    data: transformDataObject(data),
    status: res.status,
  };

  if (Token) {
    payload.token = Token;
  }

  return payload;
};

const responseInterceptor = (response) => {
  return Promise.resolve(transformResponse(response));
};

const axiosInstance = axios.create(axiosParams);

const authInterceptor = (config) => {
  const token = getToken();
  const socketId = socket.id;

  if (config.headers) {
    if (token) config.headers.Authorization = `Bearer ${token}`;
    if (socketId) config.headers['x-socket-id'] = socketId;
  }
  return config;
};

axiosInstance.interceptors.request.use(authInterceptor);

axiosInstance.interceptors.response.use(responseInterceptor, errorInterceptor);

const apiCall = (opts) => {
  const axios = axiosInstance;
  const { url, method, body, data, retries = 0, retryDelay = 0 } = opts;
  axiosRetry(axios, {
    retries,
    retryDelay: () => retryDelay,
  });

  const config = {
    url,
    method,
    headers: opts.headers || {},
    data: body || data,
  };

  return axios(config);
};

export default apiCall;

<template>
  <li class="nav-item">
    <component
      :is="tag"
      class="nav-link"
      v-bind="$attrs"
      @click.native="toggleMenu"
    >
      <div class="link-content">
        <slot>
          <i v-if="icon" :class="icon"></i>
          <p>{{ name }}</p>
        </slot>
        <i
          v-if="children && children.length"
          class="fas"
          :class="{'fa-chevron-down': isExpanded, 'fa-chevron-right': !isExpanded}"
        ></i>
      </div>
    </component>
    <transition name="slide-fade">
      <ul v-if="children && children.length && isExpanded" class="nav nested-menu">
        <li v-for="(child, index) in children" :key="index" class="nav-item">
          <sidebar-link
            :to="child.to"
            :name="child.name"
            :icon="child.icon"
            :tag="child.tag"
            :children="child.children"
            v-bind="child"
          />
        </li>
      </ul>
    </transition>
  </li>
</template>

<script>
export default {
  name: "sidebar-link",
  inheritAttrs: false,
  inject: {
    autoClose: {
      default: true,
    },
    addLink: {
      default: () => {},
    },
    removeLink: {
      default: () => {},
    },
  },
  props: {
    name: String,
    icon: String,
    tag: {
      type: String,
      default: "router-link",
    },
    children: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isExpanded: false,
    };
  },
  methods: {
    toggleMenu() {
      if (this.children && this.children.length) {
        this.isExpanded = !this.isExpanded;
      } else if (this.autoClose) {
        this.$sidebar.displaySidebar(false);
      }
    },
    hideSidebar() {
      if (this.autoClose) {
        this.$sidebar.displaySidebar(false);
      }
    },
    isActive() {
      return this.$el.classList.contains("active");
    },
  },
  mounted() {
    if (this.addLink) {
      this.addLink(this);
    }
  },
  beforeDestroy() {
    if (this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el);
    }
    if (this.removeLink) {
      this.removeLink(this);
    }
  },
};
</script>

<style>
.nav-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.link-content {
  display: flex;
  align-items: center;
}

.link-content p {
  margin: 0;
}

.nav.nested-menu {
  padding-left: 1.3rem;
}

.link-content i {
  font-size: 12px !important;
}

.slide-fade-enter-active, .slide-fade-leave-active {
  transition: all 0.3s ease;
}

.slide-fade-enter, .slide-fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}
.nested-menu {
  width: 10px;
}
</style>

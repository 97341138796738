<template>
  <div class="connected-users">
    <div v-for="user in connectedUsers" :key="user._id" @click="openUserProfile(user._id)">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-avatar
            v-bind="attrs"
            v-on="on"
            size="36"
            class="user-avatar"
            :style="{ backgroundColor: getRandomColor(user._id) }"
          >
            <template v-if="user.imageURL">
              <img
                :src="user.imageURL"
                :alt="`${user.firstName} ${user.lastName}`"
              />
            </template>
            <template v-else>
              <span>{{ userInitials(user) }}</span>
            </template>
          </v-avatar>
        </template>
        <span>{{ user.firstName }} {{ user.lastName }}</span>
      </v-tooltip>
    </div>

    <UserProfileModal :show.sync="showProfileModal" :profile="selectedUserProfile" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import UserProfileModal from '@/components/UserProfileModal.vue'; // Adjust the path as necessary
import apiCall from '@/shared/api/index'; // Adjust the path as necessary

export default {
  components: {
    UserProfileModal
  },
  data() {
    return {
      showProfileModal: false,
      selectedUserProfile: null
    };
  },
  computed: {
    ...mapGetters(['connectedUsers'])
  },
  methods: {
    userInitials(user) {
      if (!user) return "";
      const firstNameInitial = user.firstName.charAt(0).toUpperCase();
      const lastNameInitial = user.lastName.charAt(0).toUpperCase();
      return `${firstNameInitial}${lastNameInitial}`;
    },
    getRandomColor(seed) {
      const colors = [
        '#FFB6C1', '#FFD700', '#ADFF2F', '#00CED1',
        '#1E90FF', '#DA70D6', '#FA8072', '#20B2AA',
        '#87CEFA', '#778899',
      ];
      let hash = 0;
      for (let i = 0; i < seed.length; i++) {
        hash = (hash << 5) - hash + seed.charCodeAt(i);
        hash |= 0;
      }
      const index = Math.abs(hash) % colors.length;
      return colors[index];
    },
    async openUserProfile(userId) {
      try {
        const response = await apiCall({ url: `/profile/${userId}`, method: 'GET' });
        this.selectedUserProfile = response.data;
        this.showProfileModal = true;
      } catch (error) {
        console.error('Error fetching user profile by ID:', error);
      }
    }
  }
};
</script>

<style scoped>
.connected-users {
  display: flex;
  align-items: center;
}
.user-avatar {
  margin-right: 8px;
  cursor: pointer;
}
</style>

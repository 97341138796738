export function isJsonString(x) {
  try {
    const o = JSON.parse(x);
    if (o && typeof o === 'object') {
      return true;
    }
  } catch (e) {
    return false;
  }
  return false;
}
export const getRouterQueryValue = (key, url) => {
  const params = new URLSearchParams(url || location.search);
  return params.get(key);
};
export const getToken = () => {
  return getRouterQueryValue('token') || localStorage.getItem('token');
};

import Vue from 'vue';
import Vuex from 'vuex';
import { login as authLogin, logout as authLogout, isAuthenticated, getUserProfile } from '@/services/auth';
import socket from '@/services/socket';
import { mergePermissions } from './utils.js';
import apiCall from '@/shared/api/index';
import { isJsonString } from '../shared/api/util';
import LogRocket from 'logrocket';
import { processRawQuestionsAndAnswersData } from './utils';
import { routeNames } from '@/router/routes'; // Import route names
import toLower from 'lodash/toLower';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    user: null,
    userGroupPermissions: null,
    groupPermissions: [],
    pendingInvites: [],
    teamMembers: [],
    connectedUsers: [],
    userPermissions: {},
    effectivePermissions: {},
    token: null,
    tokenExpiration: null,
    featureFlagHistoryTable: [],
    featureFlags: [],
    subscriptions: [],
    steps: {},
    recipes: [],
    routes: routeNames,
  },
  mutations: {
    setUser(state, { user, token, tokenExpiration }) {
      state.user = user;
      state.token = token;
      state.tokenExpiration = tokenExpiration;
      state.userGroupPermissions = user?.groupPermissions ? {
        ...user.groupPermissions.permissions,
        isAdmin: user.groupPermissions.name.toLowerCase() === 'admin'
      } : { isAdmin: false };
      state.userPermissions = user?.permissions || {};
      state.effectivePermissions = mergePermissions(state.userPermissions, state.userGroupPermissions);
    },
    clearUser(state) {
      state.user = null;
      state.userPermissions = {};
      state.userGroupPermissions = null;
      state.effectivePermissions = {};
      state.token = null;
      state.tokenExpiration = null;
      state.featureFlagHistoryTable = [];
      state.featureFlags = [];
      state.subscriptions = [];
    },
    setFeatureFlagHistory(state, featureFlagHistoryTable) {
      state.featureFlagHistoryTable = featureFlagHistoryTable;
    },
    setFeatureFlags(state, featureFlags) {
      state.featureFlags = featureFlags;
    },
    setSubscriptions(state, subscriptions) {
      state.subscriptions = subscriptions;
    },
    setGroupPermissions(state, groupPermissions) {
      state.groupPermissions = groupPermissions;
    },
    setPendingInvites(state, pendingInvites) {
      state.pendingInvites = pendingInvites;
    },
    setTeamMembers(state, teamMembers) {
      state.teamMembers = teamMembers;
    },
    setConnectedUsers(state, connectedUsers) {
      if (!Array.isArray(connectedUsers)) {
        console.error('Connected users is not an array:', connectedUsers);
        return;
      }
      if (state.user) {
        state.connectedUsers = connectedUsers.filter(user => user._id !== state.user._id);
      } else {
        state.connectedUsers = connectedUsers;
      }
    },
    ADD_ANSWER(state, { stepId, questionId, answer }) {
      Vue.set(state.steps[stepId].questions[questionId].answers, answer.AnswerId, answer);
    },
    REMOVE_ANSWER(state, { stepId, questionId, index }) {
      Vue.delete(state.steps[stepId].questions[questionId].answers, index);
    },
    MOVE_ANSWER(state, { fromStep, fromQuestion, toStep, toQuestion, answerIndex }) {
      const answer = state.steps[fromStep].questions[fromQuestion].answers.splice(answerIndex, 1)[0];
      state.steps[toStep].questions[toQuestion].answers.push(answer);
    },
    EDIT_QUESTION(state, { recipeId, stepId, questionId, field, value }) {
      Vue.set(state.steps[recipeId][stepId].questions[questionId], field, value);
    },
    EDIT_ANSWER(state, { recipeId, stepId, questionId, answerId, field, value }) {
      const answer = state.steps[recipeId][stepId].questions[questionId].answers.find(a => a.AnswerId === answerId);
      if (answer) {
        Vue.set(answer, field, value);
      }
    },
    EDIT_STEP_METADATA(state, { recipeId, stepId, field, value }) {
      Vue.set(state.steps[recipeId][stepId].stepsMetaData[0], field, value);
    },
    SET_STEPS(state, { recipeId, steps }) {
      Vue.set(state.steps, recipeId, steps);
    },
    SET_RECIPES(state, recipes) {
      state.recipes = recipes;
    },
    SET_ROUTES(state, routes) {
      state.routes = routes;
    },
  },
  actions: {
    async login({ commit }, credentials) {
      try {
        const { user, token, tokenExpiration } = await authLogin(credentials.email, credentials.password);
        commit('setUser', { user, token, tokenExpiration });
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('token', token);
        localStorage.setItem('tokenExpiration', tokenExpiration);

        // LogRocket identify
        LogRocket.identify(user._id, {
          name: `${user.firstName} ${user.lastName}`,
          email: user.email
        });

      } catch (error) {
        console.error('Error logging in:', error);
        throw error;
      }
    },
    async fetchUserProfile({ commit }) {
      try {
        const user = await getUserProfile();
        const token = localStorage.getItem('token');
        const tokenExpiration = localStorage.getItem('tokenExpiration');
        commit('setUser', { user, token, tokenExpiration });
        localStorage.setItem('user', JSON.stringify(user));
      } catch (error) {
        console.error('Error fetching user profile:', error);
        commit('clearUser');
      }
    },
    async fetchHistoryTable({ commit }) {
      try {
        const response = await apiCall({ url: '/featureFlagHistory' });
        commit('setFeatureFlagHistory', response.data);
        return response.data;
      } catch (error) {
        console.error('Error fetching feature flag history:', error);
      }
    },
    async fetchFeatureFlags({ commit }) {
      try {
        const response = await apiCall({ url: '/featureFlags' });
        commit('setFeatureFlags', response.data);
        return response.data;
      } catch (error) {
        console.error('Error fetching feature flags:', error);
      }
    },
    async fetchSubscriptions({ commit }) {
      try {
        const response = await apiCall({ url: '/subscriptions' });
        commit('setSubscriptions', response.data);
        return response.data;
      } catch (error) {
        console.error('Error fetching subscriptions:', error);
      }
    },
    async subscribeToEvent({ dispatch }, { event, features, allFeatures }) {
      try {
        await apiCall({ url: '/subscribe', method: 'POST', data: { event, features, allFeatures } });
        await dispatch('fetchSubscriptions');
      } catch (error) {
        console.error('Error subscribing to event:', error);
      }
    },
    async unsubscribeFromEvent({ dispatch }, { event, features }) {
      try {
        let url = `/unsubscribe/${event}`;
        if (features && features !== 'all') {
          url += `/${features}`;
        }
        await apiCall({ url, method: 'DELETE' });
        await dispatch('fetchSubscriptions');
      } catch (error) {
        console.error('Error unsubscribing from event:', error);
      }
    },
    async fetchGroupPermissions({ commit }) {
      try {
        const response = await apiCall({ url: '/groupPermissions', method: 'GET' });
        commit('setGroupPermissions', response.data);
      } catch (error) {
        console.error('Error fetching group permissions:', error);
      }
    },
    async fetchPendingInvites({ commit }) {
      try {
        const response = await apiCall({ url: '/pending-invites', method: 'GET' });
        commit('setPendingInvites', response.data.map(invite => ({
          ...invite,
          firstName: invite.firstName || 'N/A',
          lastName: invite.lastName || 'N/A',
          groupPermissions: invite.groupPermissions || { name: 'N/A' }
        })));
      } catch (error) {
        console.error('Error fetching pending invites:', error);
      }
    },
    async fetchTeamMembers({ commit }) {
      try {
        const response = await apiCall({ url: '/users', method: 'GET' });
        commit('setTeamMembers', response.data.map(member => ({
          ...member,
          groupPermissions: member.groupPermissions || { name: 'N/A' }
        })));
      } catch (error) {
        console.error('Error fetching team members:', error);
      }
    },
    async inviteUser({ dispatch }, { email, groupPermissionsId }) {
      try {
        await apiCall({ url: '/invite', method: 'POST', data: { email, groupPermissionsId } });
        await dispatch('fetchPendingInvites'); // Refresh the pending invites list
      } catch (error) {
        throw error;
      }
    },
    async revokeInvite({ dispatch }, inviteId) {
      try {
        await apiCall({ url: `/revoke/${inviteId}`, method: 'PUT' });
        await dispatch('fetchPendingInvites'); // Refresh the pending invites list
      } catch (error) {
        throw error;
      }
    },
    async revokeMembership({ dispatch }, memberId) {
      try {
        await apiCall({ url: `/revoke/${memberId}`, method: 'PUT' });
        await dispatch('fetchTeamMembers'); // Refresh the team members list
      } catch (error) {
        throw error;
      }
    },
    logout({ commit }) {
      authLogout();
      commit('clearUser');
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      localStorage.removeItem('tokenExpiration');
    },
    checkAuth({ commit, dispatch }) {
      const user = isJsonString(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : null;
      const token = localStorage.getItem('token');
      const tokenExpiration = localStorage.getItem('tokenExpiration');
      const now = new Date().getTime();

      if (token && tokenExpiration && now < tokenExpiration) {
        commit('setUser', { user, token, tokenExpiration });
        dispatch('fetchUserProfile');
      } else {
        commit('clearUser');
      }
    },
    async addAnswer({ commit }, payload) {
      commit('ADD_ANSWER', payload);
      await apiCall({
        url: `/answers`,
        method: 'POST',
        data: payload
      });
    },
    async removeAnswer({ commit }, payload) {
      commit('REMOVE_ANSWER', payload);
      await apiCall({
        url: `/answers/${payload.answerId}`,
        method: 'DELETE'
      });
    },
    async moveAnswer({ commit }, payload) {
      commit('MOVE_ANSWER', payload);
      await apiCall({
        url: `/answers/move`,
        method: 'PUT',
        data: payload
      });
    },
    async editQuestion({ commit }, payload) {
      commit('EDIT_QUESTION', payload);
      await apiCall({
        url: `/questions/${payload.questionId}`,
        method: 'PUT',
        data: payload
      });
    },
    async editAnswer({ commit }, payload) {
      commit('EDIT_ANSWER', payload);
      await apiCall({
        url: `/answers/${payload.answerId}`,
        method: 'PUT',
        data: payload
      });
    },
    async editStepMetadata({ commit }, payload) {
      commit('EDIT_STEP_METADATA', payload);
      await apiCall({
        url: `/steps/${payload.stepId}/metadata`,
        method: 'PUT',
        data: payload
      });
    },
    async fetchRecipe({ commit }, recipeId) {
      try {
        const response = await apiCall({ url: `/recipes/${recipeId}` });
        const stepsData = processRawQuestionsAndAnswersData(response.data);
        commit('SET_STEPS', { recipeId, steps: stepsData.steps });
        return response;
      } catch (error) {
        console.error('Error fetching recipe:', error);
      }
    },
    async fetchRecipes({ commit }) {
      try {
        const response = await apiCall({ url: '/recipes' });
        commit('SET_RECIPES', response.data);
      } catch (error) {
        console.error('Error fetching recipes:', error);
      }
    },
    async fetchRoutes({ commit }) {
      try {
        commit('SET_ROUTES', routeNames);
      } catch (error) {
        console.error('Error fetching routes:', error);
      }
    },
    async fetchConnectedUsers({ commit }) {
      try {
        const response = await apiCall({ url: '/connected-users', method: 'GET' });
        commit('setConnectedUsers', response.data);
      } catch (error) {
        console.error('Error fetching connected users:', error);
      }
    },
  },
  getters: {
    user: state => state.user,
    userPermissions: state => state.userPermissions,
    userGroupPermissions: state => state.userGroupPermissions,
    effectivePermissions: state => state.effectivePermissions,
    isAuthenticated: state => !!state.user,
    token: state => state.token,
    tokenExpiration: state => state.tokenExpiration,
    canEditAll: state => state.effectivePermissions.canEditAll,
    canEditFeatures: state => state.effectivePermissions.canEdit,
    featureFlagHistoryTable: state => state.featureFlagHistoryTable,
    featureFlags: state => state.featureFlags,
    subscriptions: state => state.subscriptions,
    getStepsForRecipe: state => recipeId => state.steps[recipeId] || {},
    getStep: state => stepId => state.steps[stepId],
    getQuestions: state => (stepId, recipeId) => state.steps[recipeId][stepId]?.questions || {},
    getStepMetaData: state => (stepId, recipeId) => state.steps[recipeId][stepId]?.stepsMetaData[0] || {},
    getRecipes: state => state.recipes,
    getRoutes: state => state.routes,
    connectedUsers: state => state.connectedUsers,
  },
});

socket.on('permissionsUpdated', () => {
  console.log('permissionsUpdated');
  store.dispatch('fetchUserProfile');
  store.dispatch('fetchGroupPermissions');
  store.dispatch('fetchTeamMembers');
});

socket.on('featureFlagHistoryChanged', (data) => {
  console.log('featureFlagHistoryChanged',);
  store.dispatch('fetchHistoryTable');
  store.dispatch('fetchFeatureFlags');

  const currentUser = store?.state?.user?.firstName + ' ' + store?.state?.user?.lastName;
  const changedBy = data?.user?.firstName + ' ' + data?.user?.lastName;

  if (!data?.user || (currentUser === changedBy)) {
    return;
  }
  Vue.prototype.$notify({
    message: `Feature flag '${data.updatedFlag.key}' was changed by ${data.user.firstName} ${data.user.lastName}.`,
    icon: 'tim-icons icon-bell-55',
    horizontalAlign: 'right',
    verticalAlign: 'top',
    type: 'info',
    timeout: 6000,
  });
});

socket.on('inviteStatusChanged', () => {
  store.dispatch('fetchPendingInvites');
  store.dispatch('fetchTeamMembers');
});

socket.on('teamMemberStatusChanged', () => {
  console.log('teamMemberStatusChanged');
  store.dispatch('fetchPendingInvites');
  store.dispatch('fetchTeamMembers');
});

socket.on('connectedUsersUpdated', ({ connectedUsers, user, action }) => {
  const currentUser = `${store?.state?.user?.firstName} ${store?.state?.user?.lastName}`;
  const actionUserName = `${user?.firstName} ${user?.lastName}`;
  const currentUserID = store?.state?.user?._id;
  const actionUserID = user?._id;

  console.log('connectedUsersUpdated', connectedUsers);
  store.commit('setConnectedUsers', connectedUsers);

  if (!currentUserID || !actionUserID || actionUserID === currentUserID) {
    return;
  }

  const notifyUser = (message) => {
    Vue.prototype.$notify({
      message,
      icon: 'tim-icons icon-bell-55',
      horizontalAlign: 'right',
      verticalAlign: 'top',
      type: 'info',
      timeout: 6000,
    });
  };

  const actions = {
    login: () => notifyUser(`${actionUserName} has joined the application.`),
    logout: () => notifyUser(`${actionUserName} has left the application.`)
  };

  if (actions[action]) {
    actions[action]();
  }
});


export default store;

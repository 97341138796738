import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";
import AuthLayout from "@/layout/auth/AuthLayout.vue"; // Add a layout for authentication pages
import NotFound from "@/pages/NotFoundPage.vue";

// Auth pages
const Login = () => import(/* webpackChunkName: "auth" */ "@/pages/Login/Login.vue");
const Register = () => import(/* webpackChunkName: "auth" */ "@/pages/Registration/Registration.vue");
const ForgotPassword = () => import(/* webpackChunkName: "auth" */ "@/pages/ForgotPassword/ForgotPassword.vue");
const ResetPassword = () => import(/* webpackChunkName: "auth" */ "@/pages/ResetPassword/ResetPassword.vue");

// Admin pages
const Dashboard = () =>
  import(/* webpackChunkName: "dashboard" */ "@/pages/Dashboard/Dashboard.vue");
const Profile = () =>
  import(/* webpackChunkName: "common" */ "@/pages/Profile.vue");
const AvaPages = () =>
  import(/* webpackChunkName: "common" */ "@/pages/AvaPages/AvaPages.vue");
const PermissionsPage = () =>
  import(/* webpackChunkName: "common" */ "@/pages/Permissions/PermissionsPage.vue");
const TeamMembers = () =>
  import(/* webpackChunkName: "common" */ "@/pages/TeamMembers/TeamMembers.vue");
const About = () =>
  import(/* webpackChunkName: "common" */ "@/pages/About/About.vue");
const Alerts = () =>
  import(/* webpackChunkName: "common" */ "@/pages/Alerts/Alerts.vue");
const CreateRegistrationRecipe = () =>
  import(/* webpackChunkName: "common" */ "@/pages/CreateRegistrationRecipe/CreateRegistrationRecipe.vue");
const QuestionsEditor = () =>
  import(/* webpackChunkName: "common" */ "@/pages/QuestionsEditor/Layout.vue");

const routes = [
  {
    path: "/",
    component: DashboardLayout,
    redirect: "/dashboard",
    children: [
      {
        exportRouteName: true,
        path: "dashboard",
        name: "dashboard",
        component: Dashboard,
        meta: { requiresAuth: true },
      },
      {
        exportRouteName: true,
        path: "profile",
        name: "profile",
        component: Profile,
        meta: { requiresAuth: true },
      },
      {
        exportRouteName: true,
        path: "profile/:id",
        name: "user-profile",
        component: Profile,
        meta: { requiresAuth: true },
      },
      {
        exportRouteName: false,
        path: "ava-pages",
        name: "ava-pages",
        component: AvaPages,
        meta: { requiresAuth: true },
      },
      {
        exportRouteName: false,
        path: "create-registration-recipe",
        name: "create-registration-recipe",
        component: CreateRegistrationRecipe,
        meta: { requiresAuth: true },
      },
      {
        exportRouteName: false,
        path: "questions-editor",
        name: "questions-editor",
        component: QuestionsEditor,
        meta: { requiresAuth: true },
      },
      {
        exportRouteName: true,
        path: "permissions",
        name: "permissions",
        component: PermissionsPage,
        meta: { requiresAuth: true, requiresPermission: 'canChangePermissions' },
      },
      {
        exportRouteName: true,
        path: "team-members",
        name: "team-members",
        component: TeamMembers,
        meta: { requiresAuth: true, requiresPermission: 'canInvite' },
      },
      {
        exportRouteName: true,
        path: "alerts",
        name: "alerts",
        component: Alerts,
        meta: { requiresAuth: true, requiresPermission: 'canView' },
      },
      {
        exportRouteName: true,
        path: "about",
        name: "about",
        component: About,
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/auth",
    component: AuthLayout,
    children: [
      {
        exportRouteName: false,
        path: "login",
        name: "login",
        component: Login,
        meta: { requiresAuth: false },
      },
      {
        exportRouteName: false,
        path: "register",
        name: "register",
        component: Register,
        meta: { requiresAuth: false },
      },
      {
        exportRouteName: false,
        path: "forgot-password",
        name: "forgot-password",
        component: ForgotPassword,
        meta: { requiresAuth: false },
      },
      {
        exportRouteName: false,
        path: "reset-password",
        name: "reset-password",
        component: ResetPassword,
        meta: { requiresAuth: false },
      }
    ],
  },
  {
    path: "/login",
    redirect: "/auth/login",
  },
  {
    path: "/register",
    redirect: "/auth/register",
  },
  {
    path: "/forgot-password",
    redirect: "/auth/forgot-password",
  },
  {
    path: "/reset-password",
    redirect: "/auth/reset-password",
  },
  { path: "*", component: NotFound },
];

export default routes;

export const routeNames = [
  ...routes.flatMap(route =>
    route.children ? route.children.filter(child => child.exportRouteName).map(child => child.name) : []
  ),
  "myava"
].filter(Boolean);

console.log('routeNames', routeNames);

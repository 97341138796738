<template>
  <v-app>
    <div>
      <notifications></notifications>
      <router-view :key="$route.fullPath"></router-view>
    </div>
  </v-app>
</template>

<script>
import { isAuthenticated, logout } from '@/services/auth';
import apiCall from '@/shared/api/index';
import socket from '@/services/socket'; // Assuming you have a socket service
import { mapActions } from 'vuex';

export default {
  methods: {
    toggleNavOpen() {
      let root = document.getElementsByTagName("html")[0];
      root.classList.toggle("nav-open");
    },
    checkAuth() {
      if (!isAuthenticated()) {
        const currentRoute = this.$router.currentRoute.path;
        const allowedRoutes = ['/auth/login', '/auth/reset-password', '/auth/forgot-password'];
        logout();
        if (!allowedRoutes.includes(currentRoute)) {
          this.$router.push('/auth/login');
        }
      }
    },
    async notifyServerUserActive() {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          await apiCall({ url: '/user/active', method: 'POST' });
        } catch (error) {
          console.error('Error notifying server of active user:', error);
        }
      }
    },
    ...mapActions(['fetchConnectedUsers']),
    sendHeartbeat() {
      if (this.$store?.state?.user?._id) {
        socket.emit('heartbeat', this.$store?.state?.user._id);
      }
    }
  },
  mounted() {
    this.notifyServerUserActive();
    this.$watch("$sidebar.showSidebar", this.toggleNavOpen);

    // Send heartbeat every 30 seconds
    this.heartbeatInterval = setInterval(this.sendHeartbeat, 30000);

    // Fetch connected users every 30 seconds
    this.fetchConnectedUsersInterval = setInterval(() => {
      this.fetchConnectedUsers();
    }, 30000);
  },
  beforeDestroy() {
    clearInterval(this.heartbeatInterval);
    clearInterval(this.fetchConnectedUsersInterval);
  }
};
</script>

<style lang="scss">
* {
  direction: ltr !important;
}
#app > div > div > div.notifications > span > div {
  max-width: 90%;
}
.modal.show .modal-dialog {
  -webkit-transform: translate(0, 0%);
  transform: translate(0, 0%);
}
</style>

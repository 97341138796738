<template>
  <div class="wrapper">
    <side-bar>
      <template slot="links">
        <sidebar-link
          v-for="link in filteredLinks"
          :key="link.to"
          :to="link.to"
          :name="link.name"
          :icon="link.icon"
          :tag="link.tag"
          :children="link.children"
          v-bind="link"
        />
      </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>
      <dashboard-content @click.native="toggleSidebar"></dashboard-content>
      <content-footer></content-footer>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";

export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
  },
  computed: {
    ...mapGetters(['effectivePermissions']),
    links() {
      return [
        { to: '/dashboard', name: 'Feature Flag', icon: 'tim-icons icon-pencil', permission: 'f' },
        { to: '', name: 'ava-pages (Coming Soon)', icon: 'tim-icons icon-zoom-split', permission: 'avaPages', ignoreFromAdmin: true },
        { to: '/profile', name: 'User Profile', icon: 'tim-icons icon-single-02', permission: 's', ignoreFromAdmin: false },
        { to: '/team-members', name: 'Team Members', icon: 'tim-icons icon-badge', permission: 'canChangePermissions' },
        { to: '/permissions', name: 'Permissions', icon: 'tim-icons icon-settings-gear-63', permission: 'canChangePermissions' },
        { to: '/alerts', name: 'Alerts', icon: 'tim-icons icon-bell-55', permission: '' },
        {
          to: '',
          name: 'MyAva',
          icon: 'tim-icons icon-settings-gear-63',
          permission: 'myava',
          children: [
            { to: '/create-registration-recipe', name: 'Create Registration Recipe', icon: 'tim-icons icon-palette', permission: '' },
            { to: '/questions-editor', name: 'Questions Editor', icon: 'tim-icons icon-palette', permission: '' }
          ],
        },
        { to: '/about', name: 'About', icon: 'tim-icons icon-shape-star', permission: '' },
      ];
    },
    filteredLinks() {
      // console.log(this.effectivePermissions, 'this.effectivePermissions');
      if (this.effectivePermissions.isAdmin) return this.links.filter((link) => !link.ignoreFromAdmin);

      const filterChildren = (children) => {
        return children
          .filter((child) => {
            const hasRoutePermission = this.effectivePermissions.routes.includes(child.to.replace(/^\//, ''));
            const hasSpecificPermission = Array.isArray(child.permission)
              ? child.permission.every((perm) => this.effectivePermissions[perm])
              : this.effectivePermissions[child.permission] || !child.permission;

            return hasRoutePermission || hasSpecificPermission;
          })
          .map((child) => ({
            ...child,
            children: filterChildren(child.children || []),
          }));
      };
      return this.links
        .filter((link) => {
          const hasRoutePermission = this.effectivePermissions?.routes?.includes(link.to.replace(/^\//, '')) || this.effectivePermissions?.routes?.includes(link.permission);
          const hasSpecificPermission = this.effectivePermissions[link.permission] || !link.permission;

          return hasRoutePermission || hasSpecificPermission;
        })
        .map((link) => ({
          ...link,
          children: filterChildren(link.children || []),
        }));
    },
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
};
</script>

<style lang="scss"></style>

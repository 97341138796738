<template>
  <footer class="footer">
    <div class="container-fluid">
      <ul class="nav">
        <li class="nav-item">
          <!-- <a class="nav-link" href="#">
            AvaTrade
          </a> -->
        </li>
        <li class="nav-item">
          <!-- <a class="nav-link" href="#"> TEST </a> -->
        </li>
        <li class="nav-item">
          <!-- <a class="nav-link" href="#">
            Licenses
          </a> -->
        </li>
      </ul>
      <div class="copyright">
        © {{ year }} made with <i class="tim-icons icon-heart-2"></i> by
        <a @click="$router.push('/about')" class="hanan" target="_blank" rel="noopener"
          >Hanan Cohen</a
        >
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>
<style>
.hanan {
  color: #1e88e5 !important;
}
</style>

import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import store from "../store";
import toLower from 'lodash/toLower';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes,
  linkExactActiveClass: "active",
  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.isAuthenticated) {
      next({
        path: '/auth/login',
        query: { redirect: to.fullPath }
      });
    } else {
      const requiresPermission = to.meta.requiresPermission;
      const effectivePermissions = store.getters.effectivePermissions;

      const hasPermission = (perm) => {
        if (Array.isArray(perm)) {
          return perm.every(p => effectivePermissions[p]);
        } else {
          return effectivePermissions[perm];
        }
      };

      const isAdmin = toLower(effectivePermissions.isAdmin) === 'admin';

      const hasRoutePermission = effectivePermissions.routes && effectivePermissions.routes.includes(to.path.replace(/^\//, ''));
      if (isAdmin || hasRoutePermission) {
        next();
      }
      else if (requiresPermission && !hasPermission(requiresPermission)) {
        next({ path: '/dashboard' });
      } else if (to.meta.requiresRoutePermission && !hasRoutePermission) {
        next({ path: '/dashboard' });
      } else {
        next();
      }
    }
  } else {
    next();
  }
});

export default router;

<template>
  <div class="auth-layout">
    <header>
      <!-- Optional: Add a logo or header for your authentication pages -->
      <div class="a">
        <img src="https://www.avatrade.com/wp-content/themes/ava_trade/app/controllers/home/imgs/rebrand/logos/logo-brand-bright.svg" alt="Dashboard Logo" />
        <h1>AVA DASHBOARD</h1>
      </div>
    </header>
    <main>
      <router-view></router-view>
    </main>
  </div>
</template>

<script>
export default {
  name: "AuthLayout"
};
</script>

<style scoped>
.a {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.a h1 {
    margin-top: 10px;
    font-size: large;
    font-weight: revert;
    font-family: monospace;
}
.auth-layout {
  padding: 0px 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  height: 100%;
  background-color:#32325d;
}

header {
  margin-bottom: 20px;
}

main {
  width: 100%;
  max-width: 400px;
  padding: 0px;
  background-color: #27293d;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

h1 {
  font-size: 2em;
  color: white;
}
</style>

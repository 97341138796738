<template>
  <v-dialog v-model="show" max-width="600px">
    <v-card v-if="profile">
      <v-card-title class="headline">User Profile</v-card-title>
      <v-card-text>
        <v-avatar size="100" class="mx-auto" :style="{ backgroundColor: getRandomColor(profile._id) }">
          <img v-if="profile.imageURL" :src="profile.imageURL" :alt="`${profile.firstName} ${profile.lastName}`" />
          <span v-else>{{ userInitials(profile) }}</span>
        </v-avatar>
        <div class="text-center">
          <h3>{{ profile.firstName }} {{ profile.lastName }}</h3>
          <p>{{ profile.email }}</p>
          <p>{{ profile.phoneNumber }}</p>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <base-button type="primary" color="red darken-1" text @click="close">Close</base-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: Boolean,
    profile: Object
  },
  methods: {
    close() {
      this.$emit('update:show', false);
    },
    userInitials(user) {
      if (!user) return "";
      const firstNameInitial = user.firstName.charAt(0).toUpperCase();
      const lastNameInitial = user.lastName.charAt(0).toUpperCase();
      return `${firstNameInitial}${lastNameInitial}`;
    },
    getRandomColor(seed) {
      const colors = [
        '#FFB6C1', '#FFD700', '#ADFF2F', '#00CED1',
        '#1E90FF', '#DA70D6', '#FA8072', '#20B2AA',
        '#87CEFA', '#778899',
      ];
      let hash = 0;
      for (let i = 0; i < seed.length; i++) {
        hash = (hash << 5) - hash + seed.charCodeAt(i);
        hash |= 0;
      }
      const index = Math.abs(hash) % colors.length;
      return colors[index];
    }
  }
};
</script>
<style scoped>
h3, p {
  color: black !important;
  font-weight: bold;
}
</style>

import Vue from 'vue';
import VueRouter from 'vue-router';
import RouterPrefetch from 'vue-router-prefetch';
import App from './App.vue';
import router from './router/index';
import store from './store/index'; // Import the store
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css'; // Ensure you import Vuetify CSS
import '@mdi/font/css/materialdesignicons.css'; // Ensure you import Material Design Icons CSS
import LogRocket from 'logrocket';

import AvatradeDashboard from './plugins/avatradeDashboard';
import i18n from './i18n';
import '@fortawesome/fontawesome-free/css/all.css';
import './registerServiceWorker';
Vue.config.productionTip = false;
store.dispatch('checkAuth'); // Check auth state on app initialization

Vue.use(Vuetify);
Vue.use(AvatradeDashboard);
Vue.use(VueRouter);
Vue.use(RouterPrefetch);
if (process.env.VUE_APP_ENV === 'production') {
  console.log('LogRocket enabled');
  LogRocket.init('test-z8su9/avadashboard');
}
// background: #27293d;
//     color: rgba(255, 255, 255, 0.6);
const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#1E88E5',
        secondary: '#42A5F5',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        tableBackground: '#f5f5f5', // Custom color for table background
      },
      dark: {
        primary: '#1E88E5',
        secondary: '#42A5F5',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        tableBackground: '#27293d', // Custom color for table background in dark mode
      },
    },
  },
});

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
